// modal.service.ts

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})


export class ModalService {

  private modals: { [id: string]: BehaviorSubject<boolean> } = {};


  public openModal(id: string): void {
    if (!this.modals[id]) {
      this.modals[id] = new BehaviorSubject<boolean>(false);
    }
    this.modals[id].next(true);
  }

  public closeModal(id: string): void {
    if (this.modals[id]) {
      this.modals[id].next(false);
    }
  }

  public getModalState(id: string): Observable<boolean> {
    if (!this.modals[id]) {
      this.modals[id] = new BehaviorSubject<boolean>(false);
    }
    return this.modals[id].asObservable();
  }
}